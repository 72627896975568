(async () => {
  // TODO: SSO改良中なので、あえて残しておきます
  console.log("executing sso");

  // 動的にFormを作成してPOSTする
  const post = (path, params, method = "post") => {
    const form = document.createElement("form");
    form.method = method;
    form.action = path;

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement("input");
        hiddenField.type = "hidden";
        hiddenField.name = key;
        hiddenField.value = params[key];
        form.appendChild(hiddenField);
      }
    }
    document.body.appendChild(form);
    form.submit();
  };

  // SSO、Rails側のログイン状況をチェックして、必要であればログイン処理に移行する
  const result = await fetch(`${gon.global.libecityUrl}/identityService/customToken`, {
    mode: "cors",
    credentials: "include",
  });

  const json = await result.json();
  const customToken = json.custom_token || "";
  const ssoLoggedIn = customToken !== "";
  const isLoginPage = window.location.pathname.startsWith("/login");

  if (isLoginPage) {
    post("/login", { custom_token: customToken });
    return;
  }

  if (ssoLoggedIn && gon.logged_in) {
    return;
  }

  if (!ssoLoggedIn && gon.logged_in) {
    post("/logout", {});
    return;
  }

  post("/login", { custom_token: customToken });
})();
